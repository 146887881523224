import GlobalLayout from "components/page/GlobalLayout";
import PhysicalSecurityReportPage from "components/physical-security-report/PhysicalSecurityReportPage";
import React from "react";
import { Helmet } from "react-helmet";

export default function PhysicalSecurityReport() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Physical Security Report - Latest IT and Security Trends</title>
        <meta
          name="description"
          content="Get exclusive insight in enterprise information technology, public safety, and video surveillance from over 250 top industry experts."
        />
      </Helmet>
      <PhysicalSecurityReportPage />
    </GlobalLayout>
  );
}
